import React, { Component } from 'react';
// import Logo from '@images/logo.svg';

import Button from '@components/Button';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
    }

    componentDidCatch = (error, errorInfo) => {
        console.log(errorInfo);
        this.setState({ error });
    };

    reportFeedback = () => {
        // Sentry.showReportDialog();
    };

    render() {
        if (this.state.error) {
            return <Error />;
        }

        if (this.props.children) {
            return this.props.children;
        }

        return <Error />;
    }
}

const Error = () => (
    <div className="app-error">
        {/*<Logo alt="Logo" />*/}
        <h2>Something's gone wrong</h2>
        <p>Our team has been notified</p>
        <Button secondary link="/">
            Go home
        </Button>
    </div>
);

export default ErrorBoundary;
