import React, { useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';

import styles from './styles.module.scss';

const Tooltip = ({ text, children, placement = 'bottom' }) => {
    const [open, setOpen] = useState(false);

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                        ref={ref}
                    >
                        {children}
                    </div>
                )}
            </Reference>
            <Popper placement={placement}>
                {({ ref, style, placement, arrowProps }) =>
                    open ? (
                        <div className={styles.tooltipWrapper}>
                            <div
                                className={`${styles.tooltip}`}
                                ref={ref}
                                style={style}
                                data-placement={placement}
                            >
                                {text}
                                <div ref={arrowProps.ref} style={arrowProps.style} />
                            </div>
                        </div>
                    ) : null
                }
            </Popper>
        </Manager>
    );
};

export default Tooltip;
