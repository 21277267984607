import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import store from './src/store';
import ErrorBoundary from '@components/Error/Boundary';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-166247780');

    ReactPixel.init('930085504115468', null, {
        autoConfig: true, 	// set pixel's autoConfig
        debug: false, 		// enable logs
    });

    ReactPixel.pageView();
}

export const wrapRootElement = ({ element, location }) => {
    return (
        <Provider store={store}>
            <ErrorBoundary>{element}</ErrorBoundary>
        </Provider>
    );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.pageview(location.pathname + location.search);
    }
};
