import React, { Fragment } from 'react';
import { formatDistanceStrict } from 'date-fns';

export const formatDistanceInWordsStrict = (date1, date2) => {
    const str = formatDistanceStrict(date1, date2);
    const items = str.split(' ');
    return `${items[0]}${items[1].charAt(0)}`;
};

export function shuffleArray(array) {
    // immutable
    const shuffledArray = array.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
}

// capitalised the first letter of a sentence, useful for Formik form errors
export function capitalise(text) {
    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

// Common methods
export function isValidJSON(string) {
    return /^[\],:{}\s]*$/.test(
        string
            .replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    );
}

export function isValidStringify(value) {
    return (typeof value === 'object' && value !== null) || Array.isArray(value);
}

export function filterInternalChildren(children, components = []) {
    if (!Array.isArray(components)) components = [components];
    const results = [];
    const componentDisplayNames = components.map(
        (component) => component.displayName || component.name
    );
    /* This is the array of result since Article can have multiple times the same sub-component */
    // const type = (component.displayName && [component.displayName]) || (component.name && [component.name]);
    /* We can store the actual name of the component through the displayName or name property of our sub-component */
    React.Children.forEach(children, (child) => {
        const childType = child && child.type && (child.type.displayName || child.type.name);
        if (!componentDisplayNames.includes(childType)) {
            results.push(child);
        }
    });

    if (!results.length) return null;

    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return results;
}

// For unique sub-components (can only render one of type
export function findByType(children, components, singular = false, props = {}) {
    if (!Array.isArray(components)) components = [components];
    const results = [];

    const renderSubComponentChildren = (children) => {
        React.Children.forEach(children, (child, index) => {
            components.forEach((component) => {
                /* We can store the actual name of the component through the displayName or name property of our sub-component */
                const childType =
                    child && child.type && (child.type.displayName || child.type.name);

                // support sub components inside fragments (recursively loop the same method to add results)
                if (
                    child &&
                    child.type &&
                    child.type === Fragment &&
                    child.props &&
                    child.props.children
                ) {
                    renderSubComponentChildren(child.props.children);
                    return;
                }

                /* This is the array of result since Article can have multiple times the same sub-component */
                const type =
                    (component.displayName && [component.displayName]) ||
                    (component.name && [component.name]);

                const key =
                    (child &&
                        (child.key ||
                            (child.props &&
                                (child.props.id || child.props.value || child.props.name)))) ||
                    index;

                if (type.includes(childType)) {
                    results.push(React.cloneElement(child, { id: key, key, ...props }));
                }
            });
        });
    };

    renderSubComponentChildren(children);

    if (singular) {
        return results[0];
    }

    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return results;
}
